<template>
    <div class="setting_lists">
        <div class="other_item">
            <div
                class="label"
                @click="eyeVersion = !eyeVersion">
                Монохромный режим
            </div>
            <a-switch 
                v-model="eyeVersion" 
                size="large" />
        </div>
        <div class="other_item">
            <div class="w-full">
                <div class="mb-1 font-light" style="font-size: 16px;">Язык</div>
                <a-select 
                    :default-value="$i18n.locale " 
                    size="large" 
                    style="width: 100%;max-width: 300px;"
                    @change="changeLang">
                    <a-select-option 
                        v-for="lang in langList" 
                        :key="lang"
                        :value="lang">
                        {{ $t(lang) }}
                    </a-select-option>
                </a-select>
            </div>
        </div>
        <!--<div class="other_item">
                        <a-switch v-model="documentReverse" size="small" />
                        <div
                            class="label"
                            @click="documentReverse = !documentReverse">
                            Форма документа слева
                        </div>
                    </div>
                    <div class="other_item">
                        <a-switch size="small" />
                        <div
                            class="label">
                            Ночной режим
                        </div>
                    </div>
        <div class="other_item color_palette mt-6">
            <div class="text-lg font-light mb-2">
                Основной цвет
            </div>
            <div class="flex">
                <div 
                    v-for="(item, index) in palette" 
                    :key="index">
                    <a-tag 
                        :color="item.color" 
                        class="px-4 cursor-pointer" 
                        style="height: 35px;border-radius: 3px;"
                        @click="chnageColor(item.color)" />
                </div>
            </div>
        </div>-->
        <a-tabs default-active-key="desc" class="mt-3">
            <a-tab-pane key="desc" tab="Десктопная версия">
                <div class="card_select">
                    <div class="card_select__label">
                        Тип меню
                    </div>
                    <a-radio-group v-model="asideType">
                        <a-radio value="mini">
                            <img src="@/assets/images/menu_mini.png" />
                            <div class="r_label">Маленькое меню</div>
                        </a-radio>
                        <a-radio value="big">
                            <img src="@/assets/images/menu_big.png" />
                            <div class="r_label">Развернутое меню</div>
                        </a-radio>
                        <a-radio value="dynamic">
                            <img src="@/assets/images/menu_dyn.png" />
                            <div class="r_label">Динамическое</div>
                        </a-radio>
                        <a-radio value="header">
                            <img src="@/assets/images/menu_header.png" />
                            <div class="r_label">Меню в шапке</div>
                        </a-radio>
                    </a-radio-group>
                </div>
                <InterfaceMenuDesc />
            </a-tab-pane>
            <a-tab-pane key="mobile" tab="Мобильная версия">
                <InterfaceMenuMobile />
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
import { updateTheme } from '@/config/dynamicTheme'
import InterfaceMenuDesc from '../components/InterfaceMenuDesc.vue'
import InterfaceMenuMobile from '../components/InterfaceMenuMobile.vue'
import { langList, loadLanguageAsync, loadedLanguages } from '@/config/i18n-setup'
export default {
    components: {
        InterfaceMenuDesc,
        InterfaceMenuMobile
    },
    computed: {
        documentReverse: {
            get() {
                return this.$store.state.documentReverse
            },
            set(val) {
                this.$store.commit('TOGGLE_DOCUMENT_REVERSE', val)
            }
        },
        eyeVersion: {
            get() {
                return this.$store.state.eyeVersion
            },
            set(val) {
                this.$store.commit('TOGGLE_EYE_VERSION', val)
            }
        },
        asideType: {
            get() {
                return this.$store.state.asideType
            },
            set(val) {
                this.$store.commit('TOGGLE_ASIDE_TYPE', val)
            }
        }
    },
    data() {
        return {
            langList,
            loadedLanguages,
            palette: [
                {
                    color: '#f5222d'
                },
                {
                    color: '#fa541c'
                },
                {
                    color: '#faad14'
                },
                {
                    color: '#13c2c2'
                },
                {
                    color: '#52c41a'
                },
                {
                    color: '#1890ff'
                },
                {
                    color: '#2f54eb'
                },
                {
                    color: '#722ed1'
                }
            ]
        }
    },
    methods: {
        chnageColor(color) {
            updateTheme(color)
        },
        changeLang(lang) {
            try {
                localStorage.setItem('lang', lang)
                location.reload()
                // await loadLanguageAsync(lang)
            } catch(e) {
                console.log(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.setting_lists{
    .card_select{
        margin-bottom: 30px;
        &__label{
            margin-bottom: 10px;
            font-size: 16px;
        }
        &::v-deep{
            .ant-radio-group{
                display: grid;
                gap: 8px;
                grid-template-columns: repeat(1, minmax(0, 1fr));
                @media (min-width: 800px) {
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                }
                @media (min-width: 1000px) {
                    grid-template-columns: repeat(3, minmax(0, 1fr));
                }
                @media (min-width: 1200px) {
                    max-width: 900px;
                    grid-template-columns: repeat(4, minmax(0, 1fr));
                }
                .ant-radio-wrapper{
                    border: 1px solid var(--border2);
                    border-radius: var(--borderRadius);
                    text-align: center;
                    padding-left: 50px;
                    padding-right: 50px;
                    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                    background: #ffffff;
                    &:hover{
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
                    }
                    .ant-radio{
                        display: none;
                    }
                    &.ant-radio-wrapper-checked{
                        border-color: var(--blue);
                        background: #eff2f5;
                    }
                    img{
                        max-width: 110px;
                        margin: 0 auto;
                    }
                    .r_label{
                        margin-top: 10px;
                        color: var(--gray);
                        font-weight: 300;
                        color: #000000;
                    }
                }
            }
        }
    }
    .other_item{
        &:not(.color_palette){
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &.disabled{
            cursor: default;
            .label{
                opacity: 0.6;
            }
        }
        .label{
            width: 100%;
            font-size: 16px;
            cursor: pointer;
            font-weight: 300;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none;   /* Chrome/Safari/Opera */
            -khtml-user-select: none;    /* Konqueror */
            -moz-user-select: none;      /* Firefox */
            -ms-user-select: none;       /* Internet Explorer/Edge */
            user-select: none;  
            padding: 15px 0;
        }
    }
}
</style>